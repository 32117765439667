<template>
  <div class="cool_page">
    <canvas id="cool_3d"></canvas>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { initShader } from "@/plugins/webgl";
export default {
  setup() {
    const Store = useStore();
    const vsText = `
        void main(){
          gl_Position = vec4(0.0,0.0,0.0,1.0);
          gl_PointSize = 100.0;
        }
      `;
    const fsText = `
        void main(){
          gl_FragColor = vec4(1.0,1.0,0.0,1.0);
        }
      `;
    onMounted(() => {
      const canvas = document.querySelector("#cool_3d");
      const gl = canvas.getContext("webgl");
      initShader(gl, vsText, fsText);
      // 指定清除绘图区的颜色
      gl.clearColor(0, 0, 0, 1);
      // 清空绘图区
      gl.clear(gl.COLOR_BUFFER_BIT);
      // 绘制顶点
      gl.drawArrays(gl.POINTS, 0, 1);
    });
    let count = computed(() => Store.state.count);
    return {
      count,
    };
  },
};
</script>

<style>
.cool_page{
  width: 100%;
  height: 100%;
}
#cool_3d {
  width: 100%;
  height: 100%;
}
</style>